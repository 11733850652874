import { memo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import clsx from 'clsx';

import { Typography } from '@mui/material';
import { useStyles } from './styles';

// Modify the clipboard behavior to allow only plain text
const Clipboard = Quill.import('modules/clipboard');

class PlainClipboard extends Clipboard {
  onPaste(e: any) {
    // Prevent default behavior
    e.preventDefault();

    // Get html data from clipboard
    const text = e.clipboardData.getData('text/html');
    // Create a DOM parser to manipulate the pasted HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');

    // Function to recursively remove all inline styles from elements
    const removeInlineStyles = (element: Element) => {
      if (element.nodeType === Node.ELEMENT_NODE) {
        element.removeAttribute('style');
        // Recursively apply the same function to child elements
        // eslint-disable-next-line no-restricted-syntax
        for (const child of Array.from(new Set(element.children))) {
          removeInlineStyles(child);
        }
      }
    };

    // Apply the style removal to the body of the parsed document
    removeInlineStyles(doc.body);

    // Get the cleaned HTML string
    const sanitizedData = doc.body.innerHTML;

    this.quill.clipboard.dangerouslyPasteHTML(this.quill.getSelection().index, sanitizedData);
  }
}

// Register the new clipboard behavior
Quill.register('modules/clipboard', PlainClipboard);

interface IEditorProps {
  isRequired?: boolean,
  onChange?: (res: string) => void,
  onBlur?: (res: string) => void,
  classNameForEditor?: string,
  placeholder?: string,
  modules?: {
    [key: string]: any | { [key: string]: any }
  },
  fieldCommentTop?: string,
  fieldCommentBottom?: string,
  error?: string | null,
  value?: string | null,
}

const defaultModules:any = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

const Editor = ({ onChange, classNameForEditor, placeholder, modules, onBlur, fieldCommentTop, fieldCommentBottom, error, value, isRequired }:IEditorProps) => {
  const classes = useStyles();

  const handleChange = (result: string) => {
    if (onChange) {
      onChange(result);
    }
  };

  const handleBlur = (result: string) => {
    if (onBlur) {
      onBlur(result);
    }
  };

  return (
     <div className={classes.wrapper_editor}>
        {fieldCommentTop && (
        <Typography
           className={classes.fieldCommentTop}
           color="textSecondary"
           variant="body2"
        >
           {!isRequired ? fieldCommentTop : (
              <>
                 {fieldCommentTop}
                 <span style={{ color: 'red' }}>*</span>
              </>
           )}
        </Typography>
        )}
        <div data-cy="editor">
           <ReactQuill
              value={value || ''}
              onBlur={(a, b, c) => {
                handleBlur(c.getHTML());
              }}
              onChange={(a) => {
                handleChange(a);
              }}
              theme="snow"
              modules={modules || defaultModules}
              placeholder={placeholder}
              className={(clsx(classes.editor, classNameForEditor, {
                [classes.error]: !!error
              }))}
           />
        </div>
        {error && (
        <Typography
           color="error"
           variant="body2"
        >
           {error}
        </Typography>
        )}
        {fieldCommentBottom && (
        <Typography
           className={classes.fieldCommentBottom}
           color="textSecondary"
           variant="body2"
           fontWeight={600}
        >
           {fieldCommentBottom}
        </Typography>
        )}
     </div>
  );
};

export default memo(Editor);
